import { QUERIES, MUTATIONS } from '../schemas/manga';
import { apolloClient } from '../index';

export const fetchMangas = async (
  search = '',
  limit = 100,
  page = 1,
  sortBy = '+title'
) => {
  const { data } = await apolloClient.query({
    query: QUERIES.MANGAS,
    variables: { search, limit, page, sortBy },
  });
  return data;
};

export const fetchManga = async id => {
  const { data } = await apolloClient.query({
    query: QUERIES.MANGA,
    variables: { id },
  });
  return data;
};

export const fetchChapters = async (
  mangaId,
  limit = 100,
  page = 1,
  sortBy = '-chapter'
) => {
  const { data } = await apolloClient.query({
    query: QUERIES.CHAPTERS,
    variables: { mangaId, limit, page, sortBy },
    errorPolicy: 'all',
  });
  return data;
};

export const fetchChapter = async id => {
  const { data } = await apolloClient.query({
    query: QUERIES.CHAPTER,
    variables: { id },
  });
  return data;
};

export const notifySubscribers = async chapterId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.NOTIFY_CHAPTER_MANGA_SUBSCRIBERS,
    variables: { chapterId },
  });
  return data;
};

export const createManga = async (manga, coverImage) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.CREATE_MANGA,
    variables: { ...manga, thumbnail: coverImage },
    context: {
      hasUpload: true,
    },
  });
  return data;
};

export const updateManga = async (id, manga, coverImage = null) => {
  const variables = { ...manga, id };
  if (coverImage !== null) {
    variables.thumbnail = coverImage;
  }

  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_MANGA,
    variables,
    context: {
      hasUpload: true,
    },
  });
  return data;
};

export const deleteManga = async id => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_MANGA,
    variables: { mangaId: id },
  });
  return data;
};

export const forceDeleteManga = async id => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.FORCE_DELETE_MANGA,
    variables: { mangaId: id },
  });
  return data;
};

export const addChapterInfo = async (mangaId, chapter) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ADD_CHAPTER_INFO,
    variables: { ...chapter, mangaId },
  });
  return data;
};

export const updateChaptersIndices = async chapters => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTERS_INDICES,
    variables: { chapters },
  });
  return data;
};

export const updateChapterInfo = async chapter => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTER_INFO,
    variables: { ...chapter },
  });
  return data;
};

export const updateChapterPageOrder = async (id, pages, alternateSource) => {
  const variables = { id };
  if (pages) variables.pages = pages;
  if (alternateSource) variables.alternateSource = alternateSource;
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTER_PAGE_ORDER,
    variables,
  });
  return data;
};

export const addChapterPages = async (id, pages) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.ADD_CHAPTER_PAGES,
    variables: { id, pages },
    context: {
      hasUpload: true,
    },
  });
  return data;
};

export const setChapterThumbnail = async (id, thumbnail) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTER_THUMBNAIL,
    variables: { id, thumbnail },
  });
  return data;
};

export const updateChapterPage = async (id, pageId, page) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_CHAPTER_PAGE,
    variables: { id, pageId, page },
    context: {
      hasUpload: true,
    },
  });
  return data;
};

export const deleteChapterPage = async (id, page) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_CHAPTER_PAGES,
    variables: { id, pages: [page] },
  });
  return data;
};

export const deleteChapterPages = async (id, pages) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_CHAPTER_PAGES,
    variables: { id, pages },
  });
  return data;
};

export const deleteChapter = async chapterId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_CHAPTER,
    variables: { chapterId },
  });
  return data;
};
